import { HttpInterceptorFn } from '@angular/common/http';

let recaptchaToken: string | null = null;

export const RecaptchaInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.includes('/elector') || req.url.includes('/reclamo')) {
    const modifiedReq = req.clone({
      setHeaders: {
        'X-Recaptcha-Token': recaptchaToken || '',
      },
    });
    return next(modifiedReq);
  }
  return next(req);
};

export function setRecaptchaToken(token: string): void {
  recaptchaToken = token;
}
